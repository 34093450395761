import { Stack } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";

type ContainerProps = React.ComponentProps<typeof Stack>;

export const Container: FunctionComponent<ContainerProps> = ({ ...props }) => (
  <Stack w="full" maxW="full" px={20} {...props}>
    {props.children}
  </Stack>
);
