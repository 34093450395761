import {
  ContentInformationFragment,
  ContentInformationSessionFragment,
} from "../generated/graphql";

interface HumanContentTitleArgs {
  type: "movie" | "episode";
  title?: string | null;
  subtitle?: string | null;
  episodeNumber?: number | null;
  seasonNumber?: number | null;
}

export const getHumanContentTitle = (
  externalContent:
    | ContentInformationFragment["ExternalContent"]
    | ContentInformationSessionFragment["ExternalToInternalContent"]
) => {
  if (!externalContent) return "";
  const { UserGeneratedContent, MediaContent } = externalContent;
  if (UserGeneratedContent) {
    const { title, subtitle, seasonNumber, episodeNumber } = UserGeneratedContent;
    if (seasonNumber && episodeNumber && title) {
      return `${title} S${seasonNumber} E${episodeNumber}`;
    }
    if (title && seasonNumber) {
      return `${title} S${seasonNumber}`;
    }
    if (title && episodeNumber) {
      return `${title} E${episodeNumber}`;
    }
    if (title && subtitle) {
      return `${title}: ${subtitle}`;
    }

    return title;
  }
  if (MediaContent) {
    const { type, title, seasonNumber, episodeNumber, Show } = MediaContent;
    if (type == "episode" && Show) {
      const { title: showTitle } = Show;
      if (seasonNumber && episodeNumber && showTitle) {
        return `${showTitle} S${seasonNumber} E${episodeNumber}`;
      }
      if (title && seasonNumber) {
        return `${showTitle} S${seasonNumber}`;
      }
      if (title && episodeNumber) {
        return `${showTitle} E${episodeNumber}`;
      }

      return showTitle;
    } else {
      return title;
    }
  }
};

export const getServiceNameForHost = (host: string) => {
  switch (host) {
    case "www.youtube.com":
      return "Youtube";
    case "www.disneyplus.com":
      return "DisneyPlus";
    case "www.netflix.com":
      return "Netflix";
    case "www.joyn.de":
      return "Joyn";
    case "www.amazon.de":
      return "Amazon (DE)";
  }
  return null;
};

export const getIconForStreamingHost = (host: string) => {
  const serviceName = getServiceNameForHost(host);
  let imageUrl = "";
  switch (serviceName) {
    case "Netflix":
      imageUrl = "/img/logos/netflix.svg";
      break;
    case "DisneyPlus":
      imageUrl = "/img/logos/disney+.svg";
      break;
    case "Youtube":
      imageUrl = "/img/logos/youtube.svg";
      break;
    case "Amazon (DE)":
      imageUrl = "/img/logos/prime_video.svg";
      break;
    case "Joyn":
      imageUrl = "/img/logos/joyn.svg";
      break;
  }

  return imageUrl;
};

export const getPartyImageURL = (
  externalContent:
    | ContentInformationFragment["ExternalContent"]
    | ContentInformationSessionFragment["ExternalToInternalContent"]
) => {
  return (
    externalContent?.MediaContent?.backdropURL ||
    externalContent?.MediaContent?.stillURL ||
    externalContent?.MediaContent?.Show?.backdropURL ||
    externalContent?.UserGeneratedContent?.imageURL ||
    undefined
  );
};
