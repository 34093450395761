import { Box, Stack } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";

import { Container } from "./Container";

interface SectionProps extends React.ComponentProps<typeof Stack> {
  header?: React.ReactNode;
}

export const Section: FunctionComponent<SectionProps> = ({ header, children, ...props }) => {
  return (
    <Stack spacing={4} {...props}>
      <Container color="heading" fontWeight="bold" fontSize={18} paddingX="4%">
        {header}
      </Container>
      <Box overflowX="hidden">{children}</Box>
    </Stack>
  );
};
