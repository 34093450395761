import { Box } from "@chakra-ui/react";
import Image from "next/image";
import React, { ComponentProps, FunctionComponent, useEffect, useRef } from "react";

export const NextImage: FunctionComponent<ComponentProps<typeof Image>> = (props) => {
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (ref.current) {
      if (ref.current.complete) {
        // @ts-ignore
        props.onLoad?.();
      }
    }
  }, []);

  if (!props.src) {
    return <></>;
  }
  if (props.src.startsWith("https://image.tmdb.org")) {
    const file = props.src.split("\\").pop()?.split("/").pop();

    const src = `https://res.cloudinary.com/streamparty/image/upload/f_auto,c_limit,w_${
      props.width
    },q_${props.quality ?? 50}/tmdb/${file}`;

    return (
      <Box className="next-image-wrapper">
        <Image {...props} src={src} unoptimized={true} />
      </Box>
    );
  }

  return (
    <Box className="next-image-wrapper">
      <Image {...props} />
    </Box>
  );
};
